<template>
  <div class="container">
    <div class="container content">
      <!-- <v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <div id="program-top-info">
        <div class="program-icon-box">
          <div class="program-icon"></div>
        </div>
        <div class="title-page">
          <h1 class="name">{{ program.name }}</h1>
          <!-- Generate chips based on program award -->
          <v-chip
            v-if="program.award && program.award.center"
            :class="`ma-2 tag-chip ${program.award.center.toLowerCase()}-chip`"
          >
            {{ program.award.center }}
          </v-chip>
        </div>
      </div>
      <div id="program-info" class="box-container title-box">
        <div id="program-title-card">
          <div id="program-associated-info">
            <div class="content-panel associated-pin">
              <div class="pin-title">Grant Details</div>
              <div class="pin-list">
                <div class="pin-detail">
                  <div class="award-tag-box">
                    <div class="award-tag"></div>
                  </div>
                  <div class="program-right">
                    <div class="program-details">
                      <div class="detail">
                        <strong>Grant</strong>:
                        <a
                          v-if="program.award"
                          :href="`/grants/${program.award.award}`"
                          ><strong>{{ program.award.name }}</strong></a
                        >
                      </div>
                      <div
                        class="detail"
                        v-if="program.award && program.award.funder"
                      >
                        <strong>Funder</strong>:
                        <a
                          :href="
                            `/contacts/organizations/${program.award.funder.organization_id}`
                          "
                          v-if="program.award.funder"
                          ><strong>{{
                            program.award.funder.organization_name
                          }}</strong></a
                        >
                      </div>
                      <div
                        v-if="program.award && program.award.start_date"
                        class="detail"
                      >
                        <strong>Start Date</strong>:
                        {{ program.award.start_date | formatDate }}
                      </div>
                      <div
                        v-if="program.award && program.award.planned_end_date"
                        class="detail"
                      >
                        <strong>End Date</strong>:
                        {{ program.award.planned_end_date | formatDate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>{{ program.description }}</div>
        </div>
      </div>
      <button
        class="tablink tablinkthird"
        v-on:click="setTab('engagements')"
        v-bind:class="{ active: this.activeTab === 'engagements' }"
      >
        Engagements
      </button>
      <button
        class="tablink tablinkthird"
        v-on:click="setTab('contacts')"
        v-bind:class="{ active: this.activeTab === 'contacts' }"
      >
        Contacts
      </button>
      <button
        class="tablink tablinkthird"
        v-on:click="setTab('files')"
        v-bind:class="{ active: this.activeTab === 'files' }"
      >
        Files
      </button>
      <div
        id="overview"
        class="tabcontent"
        v-show="this.activeTab === 'engagements'"
      >
        <div>
          <h2>Direct Engagements</h2>
          <projects-card
            :endPoint="
              `projects?program=${this.$route.params.id}&highlight=true&`
            "
          ></projects-card>
        </div>
        <timeline
          :filesEndPoint="`attachments?program=${this.$route.params.id}`"
          :projectsEndPoint="`projects?program=${this.$route.params.id}`"
          :updatesEndPoint="`projects/update?program=${this.$route.params.id}`"
        />
        <h2 class="pt-8">Associated Projects</h2>
        <projects-card
          :endPoint="`projects?program=${this.$route.params.id}&`"
          :completed="true"
        ></projects-card>
      </div>
      <div
        id="clients"
        class="tabcontent"
        v-show="this.activeTab === 'contacts'"
      >
        <h2>Client Organizations</h2>
        <keep-alive>
          <organizations-table
            :endPoint="
              `contacts/organizations?ordering=name&program=${this.$route.params.id}`
            "
            :columns="['Name', 'Total Projects', 'Active Projects']"
            @organizations="clientOrganizations = $event"
          >
          </organizations-table>
        </keep-alive>
        <!-- <div>
          <h2 class="pb-8 mt-n8">Key Contacts</h2>
          <contacts-table
            :key="refreshTrigger"
            :endPoint="
              `contacts/people?ordering=name&program_key_contact=${this.$route.params.id}`
            "
            :columns="['Name', 'Job Title', 'Organization', 'Project']"
            :projects="program.projects"
            :showDeleteContactsButton="true"
          />
        </div>
        <h2 class="pt-8">All Contacts</h2>
        <contacts-table
          @update-key-contacts="refresh"
          :columns="['Name', 'Job Title']"
          :endPoint="
            `contacts/people?ordering=name&program=${this.$route.params.id}`
          "
          class="mt-8"
          :projects="program.projects"
          :showAddContactsButton="true"
        ></contacts-table> -->
      </div>
      <div id="files" class="tabcontent" v-show="this.activeTab === 'files'">
        <file-explorer
          :filesEndPoint="`attachments?program=${this.$route.params.id}`"
          :foldersEndPoint="`folders?program=${this.$route.params.id}`"
        />
      </div>
    </div>
  </div>
</template>
<style>
/* Page CSS */
.program-icon {
  height: 50px;
  width: 50px;
  margin: 0px auto;
  padding: 55px 10px 10px 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/programs.svg");
}
.program-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
}

.program-item {
  margin: 10px 0px;
}
.tablink {
  width: 33%;
}
.table-label {
  font-family: "roboto-bold";
  cursor: pointer;
}
.testimonial-button {
  width: 80px;
}
.title-box {
  width: 100%;
}
div#program-updates .box-container > div {
  display: flex;
  flex-flow: row wrap;
}

div#program-updates .box-container > div > .v-card {
  flex-basis: 100%;
}

div#program-updates .box-container {
  display: block;
}
div#program-associated-info {
  float: right;
}
</style>
<script>
import OrganizationsTable from "../components/OrganizationsTable";
import ProjectsCard from "../components/ProjectsCard";
import Timeline from "../components/Timeline.vue";
import FileExplorer from "../components/FileExplorer.vue";
import refreshTriggerMixin from "../mixins/refreshTrigger";
export default {
  mixins: [refreshTriggerMixin],
  components: {
    OrganizationsTable,
    ProjectsCard,
    Timeline,
    FileExplorer,
  },
  title() {
    return `Program Detail - GEPI.io`;
  },
  data() {
    return {
      program: {},
      activeTab: "engagements",
      clientOrganizations: [],
      programContacts: [],
      keyContacts: [],
      keyContactsRefreshTrigger: 0,
    };
  },
  created() {
    this.getProgram();
  },
  computed: {
    newProjects: function() {
      let newProjectCount = 0;
      let currentTime = Math.round(+new Date() / 1000);
      let projectTime = 0;
      if (this.program.projects) {
        for (var i = 0; i < this.program.projects.length; i++) {
          projectTime = Math.floor(
            new Date(this.program.projects[i].created_date).getTime() / 1000
          );
          if (currentTime - projectTime < 604800) {
            newProjectCount++;
          }
        }
      }

      return newProjectCount;
    },
    newTestimonials: function() {
      let newTestimonialCount = 0;
      // Get current unix timestamp
      let currentTime = Math.round(+new Date() / 1000);
      let testimonialTime = 0;
      if (this.program.testimonials) {
        for (var i = 0; i < this.program.testimonials.length; i++) {
          // Get time each testimonial was made
          testimonialTime = Math.floor(
            new Date(this.program.testimonials[i].created_date).getTime() / 1000
          );
          // Check the times against each other, increment count if it is less then 1 week (604800 seconds)
          if (currentTime - testimonialTime < 604800) {
            newTestimonialCount++;
          }
        }
      }

      return newTestimonialCount;
    },
    projectTypes: function() {
      const counts = {};
      const types = [];
      //   this.program.projects.map((p) => {
      //     counts[p.type[1]] = (counts[p.type[1]] || 0) + 1;
      //   });

      types.push(
        ["Type", "Projects"],
        ["Course", counts["Course"]],
        ["Delivery", counts["Delivery"]],
        ["Product", counts["Product"]]
      );
      return types;
    },
    projectStatuses: function() {
      const counts = {};
      const statuses = [];
      let projectCount = 0;
      if (this.program.projects) {
        projectCount = this.program.projects.length;
      }
      if (this.program.updates && this.program.updates.length) {
        this.program.updates.map((p) => {
          counts[p.status[1]] = (counts[p.status[1]] || 0) + 1;
        });

        statuses.push(
          ["Status", "Projects"],
          ["On Track", counts["On Track"] || 0],
          ["At Risk", counts["At Risk"]] || 0,
          ["Off Track", counts["Off Track"] || 0]
        );
      } else if (projectCount > 0) {
        statuses.push(
          ["Status", "Projects"],
          ["On Track", projectCount],
          ["At Risk", 0],
          ["Off Track", 0]
        );
      } else {
        statuses.push(
          ["Status", "Projects"],
          ["On Track", 0],
          ["At Risk", 0],
          ["Off Track", 0]
        );
      }
      return statuses;
    },
    // reducedContacts: function() {
    //   let contacts = this.programContacts.results;
    //   contacts.map(
    //     (c) =>
    //       !c.internal &&
    //       c.organizations.filter(
    //         (value, index, self) =>
    //           index ===
    //           self.findIndex(
    //             (o) =>
    //               o.person === value.person &&
    //               o.created_date > value.created_date
    //           )
    //       )
    //   );
    //   return contacts.slice(0, this.resultsPeople) || [];
    // },
    // uniqueClients() {
    //   let clients = this.clientOrganizations;
    //   return clients.filter(
    //     (e, i) => clients.findIndex((a) => a["id"] === e["id"]) === i
    //   );
    // },
    contactCount: function() {
      return this.programContacts.length;
    },
    orgCount: function() {
      return this.clientOrganizations.length;
    },
    activeProjects() {
      if (this.program.projects) {
        let projects = this.program.projects;
        return projects.filter((p) => p.completed_date === null).length;
      } else {
        return "";
      }
    },
    completedProjects() {
      if (this.program.projects) {
        let projects = this.program.projects;
        return projects.filter((p) => p.completed_date !== null).length;
      } else {
        return "";
      }
    },
  },
  methods: {
    getProgram: function() {
      this.$api
        .get("programs/" + this.$route.params.id + "/")
        .then((response) => {
          this.program = response.data;
          this.setPageTitle(this.program.name);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: "404" });
          }
        });
    },

    setTab(tab) {
      this.activeTab = tab;
    },

    mostRecentStatus(updates) {
      let status = "On Track";
      let lastUpdate = "update_date";
      if (updates.length > 1) {
        status = updates.reduce((a, b) =>
          a[lastUpdate] && a[lastUpdate] > b[lastUpdate] ? a : b
        )["status"][1];
      } else if (updates.length) {
        status = updates[0]["status"][1];
      }
      return status;
    },
  },
};
</script>
